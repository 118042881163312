import { RELATED_ENTRIES_QUERY } from '@/gql/queries/queries.gql';
import { parseEntries } from '@/lib/parsers/entries';
import { useQuery } from '@apollo/client';
import { EntriesFragment, RelatedEntriesQueryVariables } from '__generated__/graphql';
import createNextCacheTags from '../utils/createNextCacheTags';

/**
 * Custom hook to fetch related entries.
 *
 * @template TName - The type of entry typename.
 * @param {RelatedEntriesQueryVariables} variables - The variables for the query.
 * @returns {Object} - The result, loading state, and error state.
 */
export const useRelatedEntries = (variables: RelatedEntriesQueryVariables) => {
  const { data, loading, error } = useQuery(
    RELATED_ENTRIES_QUERY,
    createNextCacheTags({
      query: RELATED_ENTRIES_QUERY,
      variables,
    })
  );
  if (error) {
    console.log(`An error occurred with the query: ${error.message}`);
    return null;
  }

  const result = parseEntries(data?.entries as EntriesFragment[]);

  return {
    result,
    loading,
    error,
  };
};
